import * as Sentry from '@sentry/remix'
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser } from '@remix-run/react'
import { startTransition, StrictMode } from 'react'
import { hydrateRoot } from 'react-dom/client'
import LanguageProvider from './contexts/language/LanguageProvider'

Sentry.init({
  dsn: 'https://7fa9658b6bad29e9e235eafafee3fbad@o4507367918010368.ingest.de.sentry.io/4508120208310352',
  integrations: [Sentry.captureConsoleIntegration()],
  enabled: process.env.NODE_ENV === 'production',
})

async function hydrate() {
  const lang = window.document.getElementsByTagName('html')[0].lang

  startTransition(() => {
    hydrateRoot(
      document,
      <LanguageProvider language={lang}>
        <StrictMode>
          <RemixBrowser />
        </StrictMode>
      </LanguageProvider>
    )
  })
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate)
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1)
}
