import { LanguageContext } from './context'

export default function LanguageProvider({
  children,
  language = 'en',
}: {
  children: React.ReactNode
  language: string
}) {
  return <LanguageContext.Provider value={{ language }}>{children}</LanguageContext.Provider>
}
